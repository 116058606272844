import * as React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'

const Contacts = ({ data, text }) => (
    <ContactsStyled>
        <div className='wrapper'>
            <section className='left'>
                <h2>ENOMAC</h2>
                <hr/>
                <div className='box'>
                    <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Av. da Estação, LT. 2, Fração C</a>
                    <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Z. Ind. Oiã, 3770-059 Oiã</a>
                    <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Aveiro, Portugal</a>
                </div>
                <div className='box'>
                    <a href="mailto:info@enomac.pt">info@enomac.pt</a> 
                    <a href='tel:+351234726253'>+351 234 726 253</a> 
                    <small>{text.footer.phonealt}</small>
                </div>
            </section>
            <section className='center'>
                <h2>{data.department.name[0]}</h2>
                <hr/>
                <div className='box'>
                    <h4>Sérgio Queirós</h4>
                    <a href="mailto:comercial@enomac.pt">comercial@enomac.pt</a> 
                    <a href='tel:+351927495571'>+351 927 495 571</a> 
                    <small>{text.footer.phonealt2}</small>
                </div>
            </section>
            <section className='right'>
                <h2>{data.department.name[1]}</h2>
                <hr/>
                <div className='box'>
                    <h4>Sérgio Queirós</h4>
                    <a href="mailto:sergio.queiros@enomac.pt">sergio.queiros@enomac.pt</a> 
                    <a href='tel:+351914726253'>+351 914 726 253</a> 
                    <small>{text.footer.phonealt2}</small>
                </div>
            </section>
            
        </div>
    </ContactsStyled>
)


export default Contacts

const ContactsStyled = styled.div`
    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;
        color:${color.black};
        display: flex;
        justify-content: space-around;
        gap:5rem;
        text-transform:uppercase;
        line-height:2rem;
        font-weight:500;
        
        ${media.l`
            flex-direction:column;
            align-items:initial;
        `}

        hr{
            border:1px solid ${color.yellow};
        }

        h2{
            text-transform:uppercase;
            font-weight:900;
            letter-spacing: 0.2rem;
            font-size:2rem;
            color:${color.black};
        }

        .center, .right{
            h2{
                color:${color.black};
                opacity:0.3;
                font-size:1.8rem;
            }
        }

        .box{
            display:flex;
            flex-direction:column;
            margin-top:2rem;

            h4{
                font-size:1.5rem;
                font-weight:500;
                line-height:2rem;
                margin-bottom:0.5rem;
            }

            small {
                color: ${color.yellow}; 
                font-size: 9px; 
                margin-top: -10px;
            }
        }
    }

    
`