import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Map from '../components/Map'
import ContactsInfo from '../components/Contacts'
import Form from '../components/formulario/FormContact'
import { graphql } from "gatsby"
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Contacts = () => {
    const { t } = useTranslation()
    
    return (
        <Layout
            arrowColor='#ebebeb'
        >
            <Seo title="CONTACTOS | ENOMAC" />
            <Map
                title={t("contacts", { returnObjects: true }).contact.name}
                subtitle={t("contacts", { returnObjects: true }).contact.subtitle}
            />
            <ContactsInfo text={t("home", { returnObjects: true })} data={t("contacts", { returnObjects: true })} />
            <Form data={t("contacts", { returnObjects: true })} />
        </Layout>
    )
}

export default Contacts

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["contacts", "home"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`